<template>
  <v-menu
    v-if="hideByType"
    right
    offset-y
    open-on-hover
    close-on-click
  >
    <template v-slot:activator="{ on, attrs }">

        <v-btn fab x-small elevation="0"
        v-bind="attrs"
        v-on="on"
        @click.stop.prevent="handleHideItem('ALL')"
        >
      <v-icon 
        small 
        :style="{ color: hideAll ? 'red !important' : hideAny ? 'orange !important' : 'green !important' }"
      >
        {{ hideAll || hideAny ? 'mdi-eye-off' : 'mdi-eye' }}
      </v-icon>
        </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title
          @click.stop.prevent="handleHideItem('MOBILE')"
        >
          <v-icon
            :color="hideMobile ? 'red' : 'green'"
            style="cursor: pointer"
          >mdi-cellphone</v-icon>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title
          @click.stop.prevent="handleHideItem('DESKTOP')"
        >
          <v-icon
            :color="hideDesktop ? 'red' : 'green'"
            style="cursor: pointer"
          >mdi-monitor</v-icon>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title
          @click.stop.prevent="handleHideItem('TOTEM')"
        >
          <v-icon
            :color="hideTotem ? 'red' : 'green'"
            style="cursor: pointer"
          >mdi-cellphone-dock</v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-icon 
    v-else
    small 
    :style="{ color: hideAll ? 'red !important' : hideAny ? 'orange !important' : 'green !important' }"
    @click.stop.prevent="handleHideItem('ALL')"
  >
    {{ hideAll || hideAny ? 'mdi-eye-off' : 'mdi-eye' }}
  </v-icon>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'HideItem',
  props: {
    hideByType: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: null,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    hideAll: false,
    hideAny: false,
    hideMobile: false,
    hideDesktop: false,
    hideTotem: false,
  }),
  mounted () {
    this.hideAll = this.status.hideMobile && this.status.hideDesktop && this.status.hideTotem
    this.hideAny = this.status.hideMobile || this.status.hideDesktop || this.status.hideTotem
    this.hideMobile = this.status.hideMobile
    this.hideDesktop = this.status.hideDesktop
    this.hideTotem = this.status.hideTotem
  },
  methods: {
    handleHideItem (type) {
      if (type === 'ALL') {
        this.hideAny = !this.hideAny
        this.hideMobile = this.hideAny
        this.hideDesktop = this.hideAny
        this.hideTotem = this.hideAny
      }
      if (type === 'MOBILE') this.hideMobile = !this.hideMobile
      if (type === 'DESKTOP') this.hideDesktop = !this.hideDesktop
      if (type === 'TOTEM') this.hideTotem = !this.hideTotem
      this.hideAny = this.hideMobile || this.hideDesktop || this.hideTotem
      this.hideAll = this.hideMobile && this.hideDesktop && this.hideTotem
      this.updateData()
    },
    updateData () {
      const body = {
        hideMobile: this.hideMobile,
        hideDesktop: this.hideDesktop,
        hideTotem: this.hideTotem,
      }

      api.updateItem ('dtouch', `v1/private/${this.isMenu ? 'menus' : 'contents'}/`, this.id.concat('/hide-status'), body)
    }
  },
}
</script>

