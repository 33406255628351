<template>
  
        <v-btn fab x-small elevation="0"
          @click.stop.prevent="handleChangeVisibilityItem"
        >
  <v-icon 
    small 
    :style="{ color: isPrivate ? 'red !important' : 'green !important' }"
  >
    {{ isPrivate ? 'mdi-lock' : 'mdi-lock-open' }}
  </v-icon>
        </v-btn>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'HideItem',
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPrivate: false,
  }),
  watch: {
    value (v) {
      this.isPrivate = v
    },
  },
  mounted () {
    this.isPrivate = this.value
  },
  methods: {
    handleChangeVisibilityItem () {
      const body = {
        private: !this.isPrivate
      }
      api.updateItem ('dtouch', `v1/private/menus/`, this.id.concat('/visibility-status'), body)
      this.isPrivate = body.private
    },
  },
}
</script>

