<template>
  <v-alert
    :icon="isMenu 
          ? isExpand ? 'mdi-menu-down' : 'mdi-menu-right' 
          : icon"
    elevation="0"
    @click="isMenu ? onExpand(id) : null"
    style="margin-bottom: 1px;"
    :color="color"
    class="item-header"
    :style="{ 'background-color': item.menuBGColor }"
  >
  <v-row align="center" :style="{ color: item.menuTextColor }">
      <v-col sm="1">
        <v-checkbox 
          style="margin: 0; padding: 0" 
          v-model="isDelete" hide-details 
          @click.stop.prevent="handleChangeItemsToDelete"
          :color="item.menuTextColor"
          :value="item.id" 
        />
      </v-col>
      <v-col
        v-if="name"
        class="grow"
        xs11
      >
        {{ name }}<span style="font-size: 9px; opacity: 0.6" v-if="isTemp"> - programado</span>
      </v-col>
      <v-col
        v-else
        class="grow"
        xs11
      >
        <span style="opacity: 50%">{{ typeName }}</span><span style="font-size: 9px; opacity: 0.6" v-if="isTemp"> - programado</span>
      </v-col>

      <v-col class="shrink" v-if="isMenu">
        <v-btn fab x-small elevation="0"
          @click.stop.prevent="onAddItem(item)"
          :disabled="isEditing"
        >
          <v-icon 
            small 
          >
            mdi-plus-thick
          </v-icon>
        </v-btn>
      </v-col>
      <v-col class="shrink"
        v-if="isMenu && !hideVisibility"
      >
        <visibility-item
          :id="id"
          :value="item.private"
        />
      </v-col>
      <v-col class="shrink" v-if="((!isMenu && cloneContent) || (isMenu && cloneMenu))">
        <v-btn fab x-small elevation="0"
          @click.stop.prevent="onCloneItem(isMenu, id, parentID)"
          :disabled="isEditing"
        >
          <v-icon 
            small 
          >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <hide-item
          :id="id"
          :isMenu="isMenu"
          :status="item.hideStatus"
          :hideByType="hideByType"
        />
      </v-col>
      <v-col class="shrink">
        <!--v-icon 
          v-show="!isEditing || editedID === id"
          small 
          @click.stop.prevent="onEditItem(isMenu, id, parentID)"
          :disabled="isEditing"
        >
          mdi-pencil
        </v-icon-->
        <v-btn fab x-small elevation="0"
          @click.stop.prevent="onEditItem(isMenu, id, parentID)"
          :disabled="isEditing"
        >
        <v-icon 
          small 
          :style="{ opacity: isEditing && editedID !== id ? 0.2 : 1 }"
        >
          {{ editedID === id ? 'mdi-pencil-lock' : 'mdi-pencil' }}
        </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
  <!--v-toolbar
    v-if="false"
    @click="onExpand(id)"
    elevation="1"
    :key="item.key"
    :color="color"
    style="max-width:100%"
  >
      <v-icon small style="margin-right: 10px;">{{ icon }}</v-icon>
      <v-toolbar-title>
        <span>{{name}}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-icon small style="margin-right: 10px;"
        @click.stop.prevent="onEditItem(isMenu, id, parentID)"
      >
        mdi-pencil
      </v-icon>
  </v-toolbar-->
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import HideItem from './HideItem'
import VisibilityItem from './VisibilityItem'
export default {
  name: 'ItemHeader',
  components: {
    HideItem,
    VisibilityItem,
  },
  props: {
    locale: {
      type: String,
      default: null
    },
    localeSimulation: {
      type: String,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false,
    },
    editedID: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: null
    },
    parentID: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#FFFFFF',
    },
    refreshContent: {
      type: Object,
      default: null
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    onExpand: {
      type: Function,
      default: null,
    },
    onAddItem: {
      type: Function,
      default: null,
    },
    onEditItem: {
      type: Function,
      default: null,
    },
    onCloneItem: {
      type: Function,
      default: null,
    },
    onChangeItemsToDelete: {
      type: Function,
      default: null,
    },
    hideByType: {
      type: Boolean,
      default: false,
    },
    hideVisibility: {
      type: Boolean,
      default: false,
    },
    cloneMenu: {
      type: Boolean,
      default: false,
    },
    cloneContent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: null,
    typeName: null,
    id: null,
    icon: null,
    isMenu: true,
    isTemp: false,

    isDelete: [],
  }),
  watch: {
    localeSimulation () {
        if (this.isMenu) this.handleGetMenuItem(this.id)
    },
    item (v) {
      this.prepareData(v)
    },
    refreshContent (v) {
      if (v && v.id === this.id) {
        if (this.isMenu) this.handleGetMenuItem(this.id)
        else this.handleGetContentItem(this.id)
      }
    }
  },
  mounted () {
    this.prepareData(this.item)
  },
  methods: {
    prepareData (v) {
      this.itemsToDelete = []
      if (!v) return
      const typesWithNames = [27]
      this.typeName = this.$t(`contentType.${v.contentType.name}`, this.locale)
      this.id = v.id
      this.isMenu = v.isMenu
      this.icon = v.contentType.icon
      this.isTemp = v.isTemp
      if (!this.name && typesWithNames.indexOf(v.contentType.id) >= 0) this.handleGetVirtualName(v)
      else this.name = v.name
    },
    handleGetMenuItem (id) {
      api.getItem ('dtouch', `v1/private/menus/`, id)
        .then(response => {
          if (response) {
            this.name = utils.getTranslation(JSON.parse(response.Name), this.localeSimulation)
            this.isTemp = response.IsTemp
          }
        })
    },
    handleGetContentItem (id) {
      api.getItem ('dtouch', `v1/private/contents/`, id)
        .then(response => {
          if (response) {
            this.name = response.Alias
            this.isTemp = response.Availability !== null
          }
        })
    },
    handleGetVirtualName (v) {
      if (v.contentType.id)
        api.getItem ('dtouch', `v1/private/contents/`, `${v.id}/${v.contentType.id}/name`)
          .then(response => {
            if(!response) return
            this.typeName = utils.getTranslation(response.Name, this.locale)
          })
    },
    handleChangeItemsToDelete () {
      this.onChangeItemsToDelete({ id: this.item.id, isMenu: this.item.isMenu }, this.isDelete && this.isDelete.length > 0 ? true : false)
    },
  },
}
</script>
<style>
.item-header .shrink {
  padding: 5px 10px 5px 5px;
  /*min-width: 26px;*/
  min-width: 40px; 
}
.item-header .shrink .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.item-header .v-icon {
  color: rgba(0, 0, 0, 0.34) !important;
}
</style>

